import React, { useState, useRef } from "react"
import tw, { styled } from "twin.macro"
import Button from "@elements/button"
import { Link } from "gatsby"
import { navigate } from "@reach/router"
import { useStateContext } from "@context/stateContext"
import { AnimatePresence, motion } from "framer-motion"

const Form = styled(motion.form)`
  ${tw`fixed z-[999] flex flex-col col-span-12 col-start-1 py-12 -translate-x-1/2 -translate-y-1/2 bg-white top-1/2 left-1/2 w-full max-w-[460px] lg:max-w-[640px] px-5 md:px-10 lg:px-[90px] sm:col-span-7 md:col-span-6 md:col-start-2 xl:col-start-4`}

  .hp-button {
    ${tw`ml-auto`}
  }
`

const ExitButton = styled.div`
 ${tw`absolute cursor-pointer right-5 top-5`}
`
const HeadlineText = styled.div`
    ${tw`text-2xl italic font-bold text-center max-w-[290px] m-auto mb-9 font-copy`}
`

const InputWrap = styled.div`
  ${tw`relative z-10 w-full`}
`

const Label = styled.label`
  ${tw`absolute font-medium text-black transition duration-300 ease-in-out transform left-4`}
`
const Input = styled.input`
  ${tw`relative inline-block max-w-[460px] w-full px-4 py-1 text-black border-b border-black outline-none font-copy`}

  &:focus + ${Label},
  &.filled + ${Label} {
    ${tw`-translate-y-5`}
  }
`
const Select = styled.select`
  ${tw`relative inline-block w-full h-full px-4 py-1 text-black border-b border-black outline-none font-copy`}

  &:focus + ${Label},
  &.filled + ${Label} {
    ${tw`-translate-y-5`}
  }
`
const TextAreaWrap = styled.div`
  ${tw`relative max-w-[460px] w-full mt-10 mb-9`}

  ${Label} {
    ${tw`absolute bg-white top-4`}
  }
`
const TextArea = styled.textarea`
  ${tw`inline-block w-full h-48 p-4 text-black bg-transparent border border-black outline-none sm:h-40 font-copy`}

  &:focus + ${Label},
  &.filled + ${Label} {
    ${tw`-translate-y-7`}
  }
`

const PhoneText = styled.div`
    ${tw`flex items-center gap-3 font-medium`}
`

const CustomCheckbox = styled.span`
  ${tw`relative top-0 left-0 inline-block w-[13px] h-[13px] text-2xl text-white border border-black cursor-pointer font-copy`}

  & > span {
    ${tw`absolute transition duration-300 ease-in-out opacity-0 -top-3 text-green`}
  }
`
const Policy = styled.div`
  ${tw`flex mt-9 max-w-[460px]`}

  & input:checked + ${CustomCheckbox} {
    span {
      ${tw`text-black opacity-100`}
    }
  }
  & input.policy-error + ${CustomCheckbox} {
    ${tw`border-red-500`}
  }
`

const ContactOverlay = styled(motion.div)`
  ${tw`w-full h-full bg-black fixed z-[998]`}
`

const ContactPopUp = ({ moduleData }) => {
  const stateContext = useStateContext()
  const [formStatus, setFormStatus] = useState({
    state: "pending",
    message: "",
  })
  const form = useRef()

  const formSubmit = event => {
    event.preventDefault()
    setFormStatus({ state: "pending", message: "" })
    const policyBox = document.querySelector("#form_policy")
    policyBox.classList.remove("policy-error")
    if (!policyBox.checked) {
      policyBox.classList.add("policy-error")
      setFormStatus({
        state: "policy-error",
        message: "",
      })
      return
    }
    const formData = new FormData(form.current)
    const dataJson = JSON.stringify(Object.fromEntries(formData))
    fetch("https://sz2yisrz87.execute-api.eu-west-1.amazonaws.com/production/email/send", {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: dataJson,
    })
      .then(async response => {
        const data = await response.json()

        if (response.status === 200) {
          setFormStatus({ state: "success", message: data })
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            event: "formSubmission",
            formType: "Contact us",
          })
          return
        } else {
          setFormStatus({ state: "error", message: data })
          return
        }
      })
      .catch(error => setFormStatus({ state: "error", message: "" }))
  }

  const contactPopUpClose = () => {
    stateContext.dispatch({ action: "SET_CONTACT_POP_UP", data: false })
    document.body.style.overflow = 'auto'
    document.body.style.overflowX = 'hidden'
  }

  return (
    <AnimatePresence >
    {stateContext.state.contactPopUpActive &&
        <>
        <ContactOverlay onClick={contactPopUpClose} initial={{ opacity: 0.8 }} animate={{ opacity: 0.8 }} exit={{ opacity: 0 }} transition={{ duration: 0.5 }} />
        {formStatus.state !== "success" && (
          <Form initial={{ x: "-50%" }} animate={{ x: "-50%", y: "-50%" }} exit={{ y: "-150vh" }} ref={form}>
            <ExitButton onClick={contactPopUpClose} className="exit"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 20L0 18L8 10L0 2L2 0L10 8L18 0L20 2L12 10L20 18L18 20L10 12L2 20Z" fill="black"/></svg></ExitButton>
            {(formStatus.state === "error" || formStatus.state === "policy-error") && (
              <motion.div initial={{ y: -20 }} animate={{ y: 0 }} className="response error">
                <p className="mb-5 text-sm text-red-400 lg:mb-10 font-firacode">
                  {formStatus.message !== "" && formStatus.message === `Missing final '@domain'` && <span>Bitte geben Sie eine gültige E-Mail Adresse!</span>}
                  {formStatus.state === "policy-error" && <span>Bitte akzeptieren Sie die Datenschutzbestimmungen um das Formular abzuschicken!</span>}
                  {formStatus.message !== "" && formStatus.message === `Missing parameters! Make sure to add parameters 'email', 'name', 'content'.` && (
                    <span>Bitte füllen Sie alle Felder aus, um das Formular abzusenden.</span>
                  )}
                  {formStatus.state !== "policy-error" &&
                    formStatus.message !== "" &&
                    formStatus.message !== `Missing final '@domain'` &&
                    formStatus.message !== `Missing parameters! Make sure to add parameters 'email', 'name', 'content'.` && <span>Bitte laden sie die Seite neu und versuchen Sie es nocheinmal!</span>}
                </p>
              </motion.div>
            )}
            <HeadlineText>Schreib eine Nachricht oder ruf direkt bei uns an!</HeadlineText>
            <div className="flex-col sm:flex gap-[25px]">
              <InputWrap className="mb-8 input-wrap sm:mb-0 sm:mr-4">
                <Input
                  id="name"
                  name="name"
                  type="text"
                  onChange={e => {
                    e.target.value !== "" ? e.target.classList.add("filled") : e.target.classList.remove("filled")
                  }}
                />
                <Label htmlFor="name" className="symbol-code">{`Ihr Name`}</Label>
              </InputWrap>
              <InputWrap className="input-wrap">
                <Input
                  id="email"
                  name="email"
                  type="email"
                  onChange={e => {
                    e.target.value !== "" ? e.target.classList.add("filled") : e.target.classList.remove("filled")
                  }}
                />
                <Label htmlFor="email" className="symbol-code">{`Ihre E-Mail`}</Label>
              </InputWrap>
            </div>
            <TextAreaWrap>
              <TextArea
                name="content"
                id="content"
                onChange={e => {
                  e.target.value !== "" ? e.target.classList.add("filled") : e.target.classList.remove("filled")
                }}
              />
              <Label htmlFor="content" className="symbol-code">{`Ihre Nachricht`}</Label>
            </TextAreaWrap>
            <PhoneText>
                <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M14.3401 19.5C8.48591 19.5 0 11.0494 0 5.07294C0 2.71301 1.4668 0.5 3.97201 0.5C4.49771 0.5 6.86989 4.57352 6.86989 5.07619C6.86989 5.89872 4.43931 7.09989 4.43931 7.82779C4.43931 9.59692 9.84563 15.0348 11.6045 15.0348C12.3282 15.0348 13.5223 12.59 14.3401 12.59C14.4926 12.59 18.721 14.839 18.8508 15.2241C19.7464 17.3196 16.404 19.5 14.3401 19.5H14.3401Z" fill="black"/>
                </svg>
                +49 (0)89 / 41 11 55 55
            </PhoneText>
            <Policy>
              <input className="absolute z-10 w-[13px] h-[13px] border border-black opacity-0 border-radius-0" type="checkbox" id="form_policy" />
              <CustomCheckbox>
                <span>&#10003;</span>
              </CustomCheckbox>
              <label className="w-11/12 ml-4 font-medium font-firacode text-lightGrey text-10" htmlFor="form_policy">
                {`Ich habe die`}{" "}
                <Link
                  className="text-piggy"
                  onClick={() => {
                    navigate("/datenschutz/")
                    // closeContactModal()
                  }}
                  to="/datenschutz/"
                >{`Datenschutzhinweise`}</Link>{" "}
                gelesen und bin mir über die Art und Weise, wie die Hattenberger GmbH Daten erhebt, verbreitet und nutzt, im Klaren.
              </label>
            </Policy>
            {/* <input type="submit" value="Send" /> */}
            <Button
              onClick={formSubmit}
              type="submit"
              buttonHref="/"
              buttonText="Jetzt absenden"
              buttonClasses="mt-5 bg-yellow text-black font-bold uppercase text-14 2xl:text-base font-primary py-4 px-8 text-center inline-block mx-auto"
            />
          </Form>
        )}
        {formStatus.state === "success" && (
          <motion.div
            initial={{ x: "-50%" }} animate={{ x: "-50%", y: "-50%" }} exit={{ y: "-100vh" }}
            className="fixed z-[999] bg-white top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col  col-span-12 col-start-1 text-center response thank-you mt-c32 md:mt-c60 sm:col-span-7 md:col-span-6 md:col-start-2 xl:col-start-4 px-5 md:px-10 lg:px-[90px] py-10 w-full max-w-[460px] lg:max-w-[640px]"
          >
            <ExitButton onClick={contactPopUpClose} className="exit"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 20L0 18L8 10L0 2L2 0L10 8L18 0L20 2L12 10L20 18L18 20L10 12L2 20Z" fill="black"/></svg></ExitButton>
            <p className="pb-6 text-2xl font-bold text-black font-ubuntu">Vielen Dank für Ihre Anfrage!</p>
            <p className="text-base text-black font-firacode">Wir haben Ihre Nachricht erhalten und werden uns zeitnah bei Ihnen melden!</p>
          </motion.div>
        )}
      </>
    }
    
    </AnimatePresence>
  )
}

export default ContactPopUp
